<template>
  <div>
    <div class="banner">
      <warning-tip code="GOODS_RANK"></warning-tip>
      <main-top title="关键字查排名" desc="" spend="GOODS_RANK" />
      <auto-layout>
        <bordure-row title="搜索关键字" class="detail-body" shadow  >
          <div class="multipleSearch">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" :rules="rules" ref="formInline">
              <el-form-item label="来源类型:" style="width:100%;">
                <el-radio-group v-model="formInline.media" size="small">
                  <!-- <el-radio-button label=0 class="same_radio_diy">电脑端</el-radio-button> -->
                  <el-radio-button label="1" class="same_radio_diy">手淘APP</el-radio-button>
                  <!-- <el-radio-button label=2 class="same_radio_diy">手机网页端</el-radio-button>
                  <el-radio-button label=3 class="same_radio_diy">天猫APP</el-radio-button> -->
                </el-radio-group>
              </el-form-item>
              <el-form-item label="排序方式:" style="width:100%;" size="small">
                <el-radio-group v-model="formInline.sort">
                  <el-radio-button  v-for="(item,index) in sortOptions" :key="index"  :label="item.value" class="same_radio_diy">{{item.name}}</el-radio-button>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="宝贝链接/id:" prop="goodsId" style="width:100%;" size="small">
                <el-input v-model="formInline.goodsId" placeholder="请输入宝贝ID或宝贝链接"></el-input>
              </el-form-item>
              <el-form-item label="目标关键词:" prop="keyword" style="width:100%;" size="small">
                <el-input v-model="formInline.keyword" placeholder="请输入目标关键词"></el-input>
              </el-form-item>
            </el-form>
            <div style="text-align: center;">
              <el-button type="primary" @click="submitSearch()" size="small" style="width: 250px;">搜索</el-button>
            </div>

            <el-table :data="existList" style="margin-top:20px;" size="mini">
              <el-table-column prop="item_id" label="宝贝ID"  width="150"></el-table-column>
              <el-table-column prop="title" label="宝贝">
                <template slot-scope="scope">
                  <div class="exist_row">
                    <div class="exist_row_img">
                      <img :src="scope.row.pic_path" alt="宝贝图片">
                    </div>
                    <div class="exist_row_title">{{scope.row.title}}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="排名" width="200">
                <template slot-scope="scope">
                  <div>
                    宝贝排名-——( 第<span style="color:red;">{{scope.row.goodsPos}}</span>位)
                  </div>
                </template>
              </el-table-column>
            </el-table>



          </div>
        </bordure-row>

        <bordure-row :title="sortName" class="detail-body" shadow  >

          <div class="shop_list">
            <div class="shop_itme" v-for="(item,index)  in shopList" :key="index" :class="item.pitch ? 'shop_pitch':'' ">
              <div class="shop_itme_index">{{index+1}}</div>
              <div class="shop_itme_img"><img :src="item.pic_path" alt=""></div>
              <div class="shop_itme_content">
                <p class="shop_itme_href">
                  <a :href="item.auctionURL" target="_blank">{{item.title}}</a>
                </p>
                <p class="shop_itme_price"><span style="margin-right:10px;">价格:{{item.price}}</span><span>销量:{{item.sold}}</span></p>
                <div class="shop_itme_site">
                  <p class="shop_itme_name"><span>店铺:{{item.nick}}</span></p>
                </div>
              </div>
            </div>
            <div class="shop_time" v-if="shopList == null || shopList.length == 0">
                <div style="text-align:center;color:#999;">暂无数据</div>
            </div>
          </div>



        </bordure-row>
      </auto-layout>
      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>

<script>
import {
  queryGoodsTop2
} from "../../request/api"; //这里是引入请求
import Introduce from "../public/Introduce";
  export default {
    data() {
      return {
        CanYouClick: true,
        //排序方式，1-综合排序,2-新品排序,3-销量由高到低,4-销量由低到高,5-价格由高到低,6-价格由低到高,7-信用由高到低,8-信用由低到高
        sortOptions: [
          //单选框
          {
            value: "1",
            name: "综合排序",
          },
          {
            value: "2",
            name: "新品排序",
          },
          {
            value: "3",
            name: "销量由高到低",
          },
          {
            value: "4",
            name: "销量由低到高",
          },
          {
            value: "5",
            name: "价格由高到低",
          },
          {
            value: "6",
            name: "价格由低到高",
          },
          {
            value: "7",
            name: "信用由高到低",
          },
          {
            value: "8",
            name: "信用由低到高",
          },
        ],
        // 基本信息data
        formInline: {
          media:"1",
          goodsId: "",
          keyword: "",
          sort: "1",
        },
        shopList: [],
        existList: [], //匹配中的宝贝
        //表单的校验规则
        rules: {
          goodsId: [{
              required: true,
              message: "请输入宝贝ID或宝贝链接",
              trigger: "blur",
            },
            {
              min: 5,
              message: "请输入正确的宝贝ID或宝贝链接",
              trigger: "blur"
            },
          ],
          keyword: [{
              required: true,
              message: "请输入目标关键词",
              trigger: "blur"
            },
            {
              min: 1,
              message: "请输入正确的目标关键词",
              trigger: "blur"
            },
          ],
        },
        ranking: 1,
        introduce: [{
          title: "关键字查排名查询的作用？",
          icon: "&#xe60a;",
          content: [
            "获取单品关键词在系统中的排名，可以作为关键词seo优化的有效依据之一",
          ],
        }, ], //介绍相关数组
      };
    },
    components: {
      Introduce,
    },
    methods: {
      submitSearch() {
        this.$refs.formInline.validate((valid) => {
          if (valid) {
            let inputGoodsId = new String(this.formInline.goodsId) || "";
            inputGoodsId = inputGoodsId.trim();
            let goodsId = '';
            if (!isNaN(inputGoodsId)) {
                goodsId = inputGoodsId;
            } else {
              goodsId = this.$comm.getUrlParam(inputGoodsId, 'id')
            }

            this.shopList = [];
            this.existList = [];
            this.$store.commit("alterJumpFlag", true);
            this.$store.commit(
              "alterLoadContent",
              `获取 ${this.formInline.keyword} 相关的数据`
            );

            this.CanYouClick = false; //不允许再点击搜索
            let reqObj = Object.assign({}, this.formInline);
            reqObj.keyword = reqObj.keyword.trim();

            queryGoodsTop2(reqObj).then((data) => {
                this.shopList = data.data.items;
                var findGoodFlag = false;

                //找到排名宝贝
                this.shopList.forEach((item, index) => {
                  if(item.item_id == goodsId){
                    //设置位置
                    item.goodsPos = index + 1;
                    this.existList.push(item);
                    item.pitch = true
                    findGoodFlag = true
                  }
                });

                //提示
                if(!findGoodFlag){
                  this.$message.warning("查询页数完成，未找到目标宝贝");
                } ;

            }).finally(() => {
              this.CanYouClick = true; //允许再次搜索
              this.$store.commit("alterJumpFlag", false);
            });

          }
        })
      },
    },
    computed:{
      sortName:function(vm){
        let sortName = "综合排序";
        let sort = vm.formInline.sort;
        vm.sortOptions.forEach((item,index) => {
          if(item.value == sort){
            sortName = item.name;
          }
        });
        return sortName;
      }
    }
  };
</script>

<style lang="less" scoped>
  .multipleSearch {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-sizing: border-box;

    /deep/.el-form-item__content {
      width: 100%;
    }

    /deep/.el-form-item__label,
    /deep/.el-input--small,
    /deep/.el-input__inner {
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
    }

    /deep/.el-form-item__error {
      color: #f70000;
      letter-spacing: 0;
    }
  }

  /* 查询后的内容标题样式 */
  .bannerContent {
    width: 1120px;
    height: 48px;
    line-height: 48px;
    background: #f8f8f8;
    padding: 0 40px;
    box-sizing: border-box;
    font-size: 14px;
    color: #333333;

    /deep/.el-radio__input.is-checked+.el-radio__label {
      color: #333333;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner {
      border-color: #5d7cff;
      background: #5d7cff;
    }
  }

  .KeywordRankingText {
    font-size: 20px;
    color: #333333;
    letter-spacing: 0.23px;
    text-align: center;
    margin-top: 30px;
  }

  .slider_line {
    width: 600px;
    padding-left: 10px;

    /deep/.el-form-item__label,
    /deep/.el-input--small,
    /deep/.el-input__inner {
      height: 33px;
      line-height: 32px;
    }
  }

  .shop_list {
    height: 610px;
    overflow-y: auto;
    margin: 0 -30px;
    padding: 0 30px;

    .shop_pitch{
      border: 3px solid red;
    }

    .shop_itme {
      display: flex;
      padding: 10px;
      margin-bottom: 10px;
      .shop_itme_index{
        width:40px;
        text-align: center;
        padding-top: 20px;
      }

      .shop_itme_img {
        width: 70px;
        height: 70px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .shop_itme_content {
      font-size: 14px;
      margin-left: 10px;
      flex: 1;

      .shop_itme_href {
        a {
          color: #535353;
          text-decoration: none;

          &:hover {
            color: #ffa800;
          }
        }
      }

      .shop_itme_price {
        color: #ea3323;
      }

      .shop_itme_site {
        display: flex;
        justify-content: space-between;

        .shop_itme_name {
          color: #8f9190;
        }

        .shop_itme_coord {
          color: #ea3323;
          font-weight: 700;
        }
      }
    }
  }

  .exist_row{
    display: flex;
    .exist_row_img{
      width: 70px;
      height: 70px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .exist_row_title{
      flex: 1;
      color: #535353;
      font-size: 14px;
      margin-left: 5px;
    }

  }

  .detail-body{
    margin-bottom:30px;
    width: 48%;
  }

 @media screen and (max-width: 640px){
    .detail-body{
      width: 100% !important;
    }

  }

</style>